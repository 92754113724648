<template>
  <div class="dashboard-container">
    <div class="dashboard-top">
      <div class="dashboard-top-date-picker">
        <a-range-picker
          @change="onChange"
          :ranges="ranges"
          v-model:value="fromToDatelist"
          format="YYYY/MM/DD"
          @openChange="onDatePickerOpen"
        />
      </div>
    </div>
    <div class="dashboard-middle">
      <a-row style="padding-top: 38px">
        <a-col :span="12">
          <div class="dashboard-abnormal">
            <AbnormalPieChart :dealer-abnormal-pie="dealerAbnormalPie" />
          </div>
        </a-col>
        <a-col :span="12">
          <div class="dashboard-statistics">
            <StatisticsBarChart :dealer-bar-per-type="dealerBarPerType" />
          </div>
        </a-col>
      </a-row>
      <a-row
        type="flex"
        justify="center"
        style="padding-top: 18px; padding-bottom: 40px"
      >
        <a-col flex="center">
          <a-radio-group
            v-model:value="switchValue"
            class="switch-control"
            button-style="solid"
          >
            <a-radio-button value="districtMap">行政區地圖</a-radio-button>
            <a-radio-button value="districtList">行政區看板</a-radio-button>
            <!-- <a-radio-button value="districtList2">行政區看板2</a-radio-button> -->
            <!-- <a-radio-button value="bloodpressureKBar">血壓圖</a-radio-button> -->
          </a-radio-group>
        </a-col>
      </a-row>
      <template v-if="districtData.length > 0">
        <template v-if="switchValue === 'districtMap'">
          <a-row>
            <a-col :span="14">
              <MapChart
                style="“height: 100%; width: 100%"
                title="新北市"
                :district-data="districtData"
                @clickCard="clickCard"
              />
            </a-col>
            <a-col style="padding-right: 20px" :span="10">
              <a-table
                :columns="districtColumns"
                :data-source="districtData"
                :pagination="false"
                :scroll="{ y: 700 }"
                :customRow="districtRowClick"
              >
                <template #custonAbnormalTextTmp="{ text }">
                  <span style="color: red">
                    {{ text }}
                  </span>
                </template>
              </a-table>
            </a-col>
          </a-row>
        </template>
        <template v-else-if="switchValue === 'districtList'">
          <DistrictCardList
            :dealer-district-abnormal="dealerDistrictAbnormal"
            @clickCard="clickCard"
          ></DistrictCardList>
        </template>
        <!-- <template v-if="switchValue === 'districtList2'">
          <DistrictCardList
            :show-pagination="true"
            :dealer-district-abnormal="dealerDistrictAbnormal"
            @clickCard="clickCard"
          ></DistrictCardList>
        </template> -->
        <!-- <template v-else-if="switchValue === 'bloodpressureKBar'">
          <KBarChart :dealer-bar-per-type="dealerBarPerType" />
        </template> -->
      </template>
      <template v-else>
        <a-row
          type="flex"
          justify="center"
          style="padding-top: 18px; padding-bottom: 40px"
        >
          <a-col flex="center">
            <div class="no-data-info">尚無量測資料</div>
          </a-col>
        </a-row>
      </template>
    </div>
  </div>
</template>

<script>
  import AbnormalPieChart from './components/AbnormalPieChart'
  import StatisticsBarChart from './components/StatisticsBarChart'
  import KBarChart from './components/KBarChart'
  import DistrictCardList from './components/DistrictCardList'
  import dayjs from 'dayjs'
  import { mapActions, mapGetters } from 'vuex'
  import MapChart from './components/MapChart.vue'
  import { roundDecimal } from '@/utils/pocketKnives'

  export default {
    name: 'Index',
    data() {
      const ranges = {}
      ranges[this.$t('DatePicker.today')] = [dayjs(), dayjs()]
      ranges[this.$t('DatePicker.yesterday')] = [
        dayjs().subtract(1, 'day'),
        dayjs().subtract(1, 'day'),
      ]
      ranges[this.$t('DatePicker.thisMonth')] = [
        dayjs().startOf('month'),
        dayjs().endOf('month'),
      ]
      ranges[this.$t('DatePicker.latestMonth')] = [
        dayjs().subtract(1, 'month'),
        dayjs(),
      ]
      ranges[this.$t('DatePicker.latestThreeMonths')] = [
        dayjs().subtract(3, 'month'),
        dayjs(),
      ]
      ranges[this.$t('DatePicker.latestYear')] = [
        dayjs().subtract(1, 'year'),
        dayjs(),
      ]

      const districtData = []

      return {
        chart: null,
        cleanUiFlag: 0,
        fromDate: 0,
        toDate: 0,
        fromToDatelist: [],
        ranges,
        switchValue: 'districtMap',
        districtColumns: [
          { title: '地區', dataIndex: 'name' },
          { title: '量測人次', dataIndex: 'totalCount' },
          {
            title: '異常人次',
            dataIndex: 'abnormalCount',
            slots: {
              customRender: 'custonAbnormalTextTmp',
            },
          },
          {
            title: '異常比',
            dataIndex: 'rate',
            slots: {
              customRender: 'custonAbnormalTextTmp',
            },
          },
        ],
        districtData,
      }
    },
    components: {
      AbnormalPieChart,
      StatisticsBarChart,
      MapChart,
      DistrictCardList,
    },
    mounted() {
      if (this.fromToDatelist.length <= 0) {
        this.fromToDatelist.push(dayjs())
        this.fromToDatelist.push(dayjs())
        const fromDate = this.fromToDatelist[0].startOf('day').unix()
        const toDate = this.fromToDatelist[1].endOf('day').unix()
        this.fromDate = fromDate * 1000
        this.toDate = toDate * 1000
        this.getDealerAbnormalPie({
          startTs: this.fromDate,
          endTs: this.toDate,
        })
        this.getDealerBarPerType({
          startTs: this.fromDate,
          endTs: this.toDate,
        })
        this.getDealerDistrictAbnormal({
          startTs: this.fromDate,
          endTs: this.toDate,
        })
      }
    },
    computed: {
      ...mapGetters({
        title: 'user/title',
        role: 'user/role',
        dealerAbnormalPie: 'statistics/dealerAbnormalPie',
        dealerBarPerType: 'statistics/dealerBarPerType',
        dealerDistrictAbnormal: 'statistics/dealerDistrictAbnormal',
      }),
    },
    methods: {
      ...mapActions({
        getDealerAbnormalPie: 'statistics/getDealerAbnormalPie',
        getDealerBarPerType: 'statistics/getDealerBarPerType',
        getDealerDistrictAbnormal: 'statistics/getDealerDistrictAbnormal',
      }),
      onChange(date, dateString) {
        if (dateString && dateString.length == 2) {
          const fromDate = dayjs(dateString[0]).startOf('day').unix()
          const toDate = dayjs(dateString[1]).endOf('day').unix()
          this.fromDate = fromDate * 1000
          this.toDate = toDate * 1000
          this.getDealerAbnormalPie({
            startTs: this.fromDate,
            endTs: this.toDate,
          })
          this.getDealerBarPerType({
            startTs: this.fromDate,
            endTs: this.toDate,
          })
          this.getDealerDistrictAbnormal({
            startTs: this.fromDate,
            endTs: this.toDate,
          })
        }
      },
      onDatePickerOpen(status) {
        if (status === true) {
          this.cleanUiFlag++
        }
      },
      districtRowClick(record, index) {
        const that = this
        return {
          style: {
            cursor: 'pointer',
          },
          onClick: (event) => {
            that.goToVillageInfo(record)
          },
        }
      },
      goToVillageInfo(record) {
        this.$router.push({
          name: 'VillageInfo',
          params: {
            fid: 100,
          },
          query: {
            fromDate: this.fromDate,
            toDate: this.toDate,
            district: record.name,
          },
        })
      },
      clickCard(district) {
        this.$router.push({
          name: 'VillageInfo',
          params: {
            fid: 100,
          },
          query: {
            fromDate: this.fromDate,
            toDate: this.toDate,
            district: district,
          },
        })
      },
    },
    watch: {
      dealerDistrictAbnormal: function (newData) {
        this.districtData = []
        if (newData) {
          newData.forEach((item, index) => {
            const total = parseInt(item.NumberOfMeasurements)
            const abnormalCount = parseInt(item.NumberOfAbnormalMeasurements)

            let rate = 0
            if (total > 0) {
              rate = Math.floor((abnormalCount * 100) / total)
            }

            this.districtData.push({
              key: index,
              name: item.district,
              totalCount: total,
              abnormalCount: abnormalCount,
              rate: `${rate}%`,
            })
          })
        }
      },
    },
  }
</script>

<style lang="less" scoped>
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background-color: #4ebaa8;
    border-color: #4ebaa8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 2px !important;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #fff;
    background: #fff;
    // border-color: red;
    // -webkit-box-shadow: -1px 0 0 0 red;
    // box-shadow: -1px 0 0 0 red;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 2px !important;
  }

  .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #4dbaa7;
    // background: #fff;
    // border-color: red;
    // -webkit-box-shadow: -1px 0 0 0 red;
    // box-shadow: -1px 0 0 0 red;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 2px !important;
  }
  .dashboard-container {
    background-color: #e5e5e5;
    padding-top: 24px;
    padding-bottom: 24px;

    .dashboard-top {
      //margin-top: 9px;
      height: 40px;
      //background-color: #e5e5e5;
      .dashboard-top-date-picker {
        float: right;
        // margin-top: 8px;
        margin-right: 84px;
        width: 274px;
      }
    }

    .dashboard-middle {
      margin-left: 32px;
      margin-right: 32px;
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
      border-radius: 8px;
      .dashboard-abnormal {
        // padding-top: 38px;
        margin-top: 38px;
        // width: 1000px;
        margin: auto;
        display: flex;
        //justify-content: center;
        align-items: center;

        // display: flex;
        // align-items: center;
        // text-align: center;
        width: 390px;
        height: 400px;

        background: #ffffff;
        /* Gray 6 */

        border: 1px solid #f2f2f2;
        border-radius: 40px;

        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      }
      .dashboard-statistics {
        margin-top: 38px;
        padding-left: 10px;
        // width: 1000px;
        margin: auto;
        display: flex;
        //justify-content: center;
        align-items: center;

        width: 390px;
        height: 400px;

        background: #ffffff;
        /* Gray 6 */

        border: 1px solid #f2f2f2;
        border-radius: 40px;

        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      }
      .switch-control {
        margin-top: 18px;
      }
      .no-data-info {
        margin-top: 122px;
        margin-bottom: 514px;
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 20px;
        color: #333333;
      }
    }
  }
</style>
